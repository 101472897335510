.teamCardCont {
    background-color: #4e121110;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    padding: 20px;
}

.teamCardCont h3 {
    margin: 0;
    font-weight: 500;
}

.teamCardCont .FlexHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

@media (max-width: 700px) {
    .teamCardCont .FlexHeader {
        flex-direction: row;
    }
}