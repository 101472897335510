.FooterIconBox {
    width: 30px;
    height: 30px;
    padding: 5px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.SocialFooterIcon {
    max-height: 30px;
    padding: 0 5px;
    cursor: pointer;
    font-size: 30px !important;
    color: black;
}