/** MOBILE FIRST DEVELOPMENT **/

.x-small {
  @apply text-[13px] font-normal leading-[2.85] tracking-normal;
}

.small {
  @apply text-[12px] lg:text-[15px] font-normal leading-[2.47] tracking-normal;
}

.large {
  @apply text-sm md:text-base 3xl:text-lg  tracking-normal;
}

.x-large {
  @apply text-base 2xl:text-lg 3xl:text-xl tracking-normal;
}

.twoX-large {
  @apply text-[15px] md:text-base lg:text-2xl tracking-normal;
}

.threeX-large {
  @apply text-base md:text-xl 2xl:text-2xl 3xl:text-3xl tracking-normal;
}

.fourX-large {
  @apply text-xl lg:text-xl 3xl:text-4xl font-bold leading-[1.15] tracking-normal;
}

.fiveX-large {
  @apply text-5xl font-normal leading-[1.04] tracking-normal;
}

.sixX-large {
  @apply text-6xl font-bold leading-[0.97] tracking-normal;
}

.sevenX-large {
  @apply text-xl lg:text-3xl 2xl:text-4xl 3xl:text-7xl font-bold leading-[1.16] tracking-normal;
}

.eightX-large {
  @apply text-2xl lg:text-4xl 2xl:text-6xl 3xl:text-9xl font-semibold leading-[1.24] tracking-normal;
}

.nineX-large {
  @apply text-11xl font-bold leading-none tracking-normal;
}

.tenX-large {
  @apply text-3xl lg:text-9xl 2xl:text-11xl 3xl:text-16xl font-semibold tracking-normal;
}
