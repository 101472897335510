.successIcon {
    font-size: 150px !important;
    color: #49aa19;
}

.nonSuccessIcon {
    font-size: 170px !important;
    color: red;
}

.appSummary {
    width: 80%;
    margin: auto;
}

@media (max-width: 1124px) {
    .appSummary {
        width: 100% !important;
    }
}