.App {
  text-align: center;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.WhatsappIcon {
  color: white;
  font-size: 40px !important;
}

.WhatsappCont {
  padding: 35px;
  background-color: #25d366;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.WhatsappCont:hover {
  transform: scale(1.1);
}

.loadingBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.loadingSmallBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.WhyList li {
  background-color: #6e1010;
  color: white;
  width: 300px;
  padding: 35px 25px 15px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.WhyListBig li {
  width: 350px;
}
.WhyListQuar li {
  width: 250px;
}

.WhyList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 55px 20px;
  justify-content: center;
  margin-top: 50px;
  position: relative;
}

.WhyList .listTick {
  color: white;
  background-color: black;
  width: 70px !important;
  height: 70px !important;
  font-size: 10px !important;
  border-radius: 50%;
  position: absolute;
  top: -45px;
  padding: 10px;
}

.WhyList .listTickNumber {
  color: white;
  background-color: black;
  width: 70px !important;
  height: 70px !important;
  font-size: 40px !important;
  border-radius: 50%;
  position: absolute;
  top: -45px;
  padding: 10px;
}

.CheckList li {
  background-color: #6e1010;
  color: white;
  width: 100%;
  padding: 10px 40px;
  border-radius: 20px;
  margin-bottom: 5px;
  text-align: center;
  position: relative;
}

.CheckList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  /* max-width: 800px; */
}

.CheckList .listTick {
  color: green;
  background-color: white;
  width: 30px !important;
  height: 30px !important;
  font-size: 20px !important;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 4.5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .downList {
    margin-top: -15px;
  }

  .WhyList li {
    width: 40%;
    padding: 30px 10px 12px;
    font-size: 15px;
  }

  .WhyListBig li {
    width: 40%;
  }

  .WhyListQuar li {
    width: 40%;
  }

  .WhyList {
    gap: 60px 20px;
  }
}
