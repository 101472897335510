.FormContainerBoxVer {
}

.confirmList li {
  padding-bottom: 5px;
}

.modalBox {
  width: 70% !important;
  margin: auto;
}

.listTitle {
  font-weight: 500;
}

@media (max-width: 1124px) {
  .modalBox {
    width: 90% !important;
  }
}
