.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 100vh;
}

.counter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 76%;
  margin: auto;
}

.CounterItem {
  width: 200px;
  height: 200px;
  position: relative;
  margin: 30px auto;
}

.CounterItem .outer {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.CounterItem .outer .inner {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
}

#number {
  font-weight: 600;
  color: #010102;
  font-size: 40px;
}

.counterText {
  text-align: center;
  font-size: 20px;
  margin: 10px 0 0 0;
  font-weight: 800;
}

circle {
  fill: none;
  stroke: #ac1e1c;
  stroke-width: 5px;
  stroke-dasharray: 750;
  stroke-dashoffset: 750;
  animation: 4s ease-out forwards;
  animation-delay: 0s;
}

@keyframes counter {
  100% {
    stroke-dashoffset: 0;
  }
}

.CounterItem svg {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 600px) {

  .counter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
  }

  .CounterItem {
    width: 140px;
    height: 140px;
    position: relative;
  }

  .CounterItem .outer {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
      -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .CounterItem .outer .inner {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
      inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
      0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  }

  .inner img {
    height: 40px !important;
  }

  .circles {
    display: none
  }

  #number {
    font-weight: 600;
    color: #010102;
    font-size: 30px;
  }

  .counterText {
    text-align: center;
    font-size: 17px;
    margin: 10px 0 0 0;
    font-weight: 800;
  }
}