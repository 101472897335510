/** MOBILE FIRST DEVELOPMENT **/

.defaultInput {
  @apply block;
}

.input {
  width: 100%;
  border: 0;
  padding: 5px;
  height: 40px;
  border-bottom: 2px solid #163F5B;
}

.inputSystem {
  width: 200px;
  margin-right: 20px;
  height: 55px;
}

.inputSystemSmall {
  width: 100px;
  margin-right: 20px;
  height: 55px;
}

.inputSystem[type="checkbox"] {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.textarea {
  @apply py-3;
}

.selectInputs {
  @apply flex flex-row-reverse items-center justify-end;
}

.bottomBorderInput {
  @apply text-text_white text-5xl text-center font-bold overflow-hidden bg-transparent border-x-0 border-t-0 border-b-2 border-text_white;
}

.AddressInput, .passwordInput {
  @apply flex flex-col items-start;
}

.passwordInput small {
  @apply mt-4;
}

.AddressInputColor {
  @apply text-text_3b3b3b;
}

.AddressInput small {
  @apply mt-1;
}

.FAQSearch {
  @apply  !w-fit;
}

/* CHECKBOX */

.checkboxOutlined,
.checkboxOutlined:checked {
  @apply mb-3 mr-1 bg-transparent rtl: ml-1 p-0 text-primary-900;
}

.checkboxOutlined {
  @apply cursor-pointer bg-transparent text-primary-900;
}

.checkboxOutlined:after {
  content: "✔";
  @apply text-xl relative -top-[10px] left-[1px] rotate-6 hidden;
}

.checkboxOutlined:checked:after {
  @apply block;
}

.solidCheckbox,
.solidCheckbox:checked {
  @apply mb-3 mr-1 rtl: ml-1 p-0 border-2 border-text_777777 bg-primary-900 text-primary-50;
}

.solidCheckbox {
  @apply cursor-pointer bg-transparent text-primary-900;
}

.solidCheckbox:after {
  content: "✓";
  @apply text-lg relative -top-[5px] left-[2px] rotate-6 hidden;
}

.solidCheckbox:checked:after {
  @apply block;
}

/* RADIO */

.selectInputs p {
  @apply ml-4 rtl: ml-0 rtl:mr-4 mt-1;
}

input[type="radio"] {
  appearance: none;
  transform: translateY(-0.075em);
  border-radius: 50% !important;
  @apply bg-primary-50 m-0 p-0 border-2 border-text_777777 grid place-content-center;
}

input[type="radio"]::before {
  content: "";
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #bf0022;
  @apply bg-primary-50 w-3 h-3;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type="search"] {
  @apply bg-[url("../../../assets/Icons//Search/search.webp")] bg-no-repeat ltr: bg-[bottom_50%_left_13px] rtl:bg-[bottom_50%_right_13px] pl-[40px] rtl:pr-[40px] rtl:pl-0;
}