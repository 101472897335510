.accIcon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
}

.accInsideIcon {
  max-height: 30px;
  cursor: pointer;
  font-size: 25px !important;
  color: black;
}

.accInsideIcon:hover {
  color: #6e1010;
}

@media (max-width: 900px) {
  .accInsideIcon {
    font-size: 22px !important;
  }
}
