.academiesContainer {
  margin: 20px 0;
}

.academiesContainer h2 {
  text-align: center;
}

.LogoContainer {
  height: 100px;
  width: 100%;
  overflow: hidden;
  display: flex;
  padding: 20px 0;
  position: relative;
}

.LogoContainer::before,
.LogoContainer::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 10vw;
  min-width: 60px;
  z-index: 3;
}

.LogoContainer::before {
  left: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}

.LogoContainer::after {
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}

.logo {
  white-space: nowrap;
  height: 100%;
  display: inline-flex;
  align-items: center;
  animation: slide 50s infinite linear;
  position: relative;
}

.logo img {
  height: 60px;
  margin: 0px 20px;
  min-width: 80px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
