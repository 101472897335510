.successIcon {
  font-size: 150px !important;
  color: #49aa19;
}

.appSummary2 {
  width: 80%;
  margin: auto;
}

.appSummary2 li {
  font-size: 18px;
}

@media (max-width: 1124px) {
  .appSummary2 {
    width: 100% !important;
  }
}
