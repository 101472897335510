.modalCont {
    background-color: white;
    padding: 20px 40px;
    border-radius: 10px;
    overflow: auto;
    max-height: 90vh;
}

.MainModal {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1124px) {
    .modalCont {
        padding: 10px 20px 30px;
    }
}