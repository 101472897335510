.flip-clock {
  --fcc-flip-duration: 0.5s; /* transition duration when flip card */
  --fcc-digit-block-width: 20px; /* digit card's width */
  --fcc-digit-block-height: 30px; /* digit card's height */
  --fcc-digit-font-size: 28px; /* font size of digit */
  /* --fcc-digit-color: #4e1211; */
  --fcc-digit-color: #000; /* color of digit */
  --fcc-background: white; /* digit card's background */
  --fcc-label-color: #ffffff; /* time label's color */
  --fcc-divider-color: #ffffff66; /* divider's color */
}

.carousellll {
  transition: none !important;
  height: 550px;
}

.videoBack {
  position: absolute;
  top: 0%;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* @media (min-aspect-ratio: 16/9) {
  .videoBack {
    width: 100%;
    height: auto;

  }
}

@media (max-aspect-ratio: 16/9) {
  .videoBack {
    width: auto;
    height: 100%;
  }
} */

@media (max-width: 600px) {
  .flip-clock {
    --fcc-flip-duration: 0.5s; /* transition duration when flip card */
    --fcc-digit-block-width: 20px; /* digit card's width */
    --fcc-digit-block-height: 28px; /* digit card's height */
    --fcc-digit-font-size: 24px; /* font size of digit */
    --fcc-digit-color: #000; /* color of digit */
    --fcc-background: white; /* digit card's background */
    --fcc-label-color: #ffffff; /* time label's color */
    --fcc-divider-color: #ffffff66; /* divider's color */
    --fcc-separator-size: 2px; /* size of colon */
    --fcc-digit-block-spacing: 25px; /* space between blocks in each unit of time */
  }
}

@media (max-width: 610px) {
  /* .flip-clock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 250px;
  } */
  /* ._2hRXr:nth-child(4) {
    display: none;
  }
  ._2hRXr:nth-child(4)::after,
  ._2hRXr:nth-child(4)::before {
    display: none;
  } */
}
