.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: white;
  box-shadow: 24px;
  padding: 32px;
  border-radius: 10px;
}

.FormTitle {
  font-size: 25px !important;
  font-weight: bold !important;
  text-align: center;
}

.small {
  max-width: 700px;
}

.smallExtra {
  max-width: 500px;
}

@media (max-width: 900px) {
  .modalStyle {
    width: 90%;
    padding: 15px;
  }

  .FormTitle {
    font-size: 20px !important;
  }

}